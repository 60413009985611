import { Grid, Typography } from "@mui/material";
import SupportRequests from "./components/SupportRequests";

const Content = () => {
  if (new URLSearchParams(window.location.search).get("loc") === null) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        flexGrow={1}
        flexShrink={0}
        px={4}
      >
        <Grid item xs={12}>
          <Typography variant="h2" align="center">
            Please scan the support QR code to access this website.
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container justifyContent="space-between" flexGrow={1} flexShrink={0}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SupportRequests />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Content;
