import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import { ReactComponent as CardIcon } from "./assets/card.svg";
import { ReactComponent as TabletIcon } from "./assets/tablet.svg";
import { ReactComponent as TerminalIcon } from "./assets/terminal.svg";
import { ReactComponent as PhoneIcon } from "./assets/phone.svg";
import { ReactComponent as ArrowRightIcon } from "./assets/arrow-right.svg";
import { ReactComponent as SuppliesIcon } from "./assets/supplies.svg";
import React, { useState } from "react";
import RequestForm from "../../../RequestForm";

const SupportCard = ({ icon: Icon, title, description, fields }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Paper
        elevation={8}
        onClick={() => setOpen(true)}
        sx={{ cursor: "pointer" }}
      >
        <Box px={6} py={4}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <Icon style={{ display: "block" }} />
                </Grid>
                <Grid item>
                  <Typography variant="h2">{title}</Typography>
                </Grid>
              </Grid>
            </Grid>
            {description && (
              <Grid item xs={12}>
                <Typography>{description}</Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <Button endIcon={<ArrowRightIcon />}>SUBMIT A REQUEST</Button>
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <RequestForm
        title={title}
        fields={fields}
        sheetName={title}
        open={open}
        onClose={() => setOpen(false)}
      />
    </>
  );
};

const SupportRequests = () => (
  <Box px={4} py={4}>
    <Grid container spacing={4}>
      <Grid item xs={12} md={6} lg={4}>
        <SupportCard
          icon={CardIcon}
          title="Request Additional Cards"
          fields={["Message"]}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <SupportCard
          icon={TabletIcon}
          title="Replace Tablet"
          fields={["Message"]}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <SupportCard
          icon={TerminalIcon}
          title="Replace Terminal"
          fields={["Message"]}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <SupportCard
          icon={SuppliesIcon}
          title="Request Supplies"
          fields={["Supplies", "Other Supplies"]}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <SupportCard
          icon={PhoneIcon}
          title="Request a Callback"
          fields={["Phone", "Message"]}
        />
      </Grid>
    </Grid>
  </Box>
);

export default SupportRequests;
