import { Box, Grid, Typography } from "@mui/material";
import { ReactComponent as Logo } from "./assets/logo.svg";

// TODO: Discuss about sticky header solution
const Header = () => {
  const date = new Date();
  const dateString = (
    date.toLocaleDateString("en-us", {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
    }) +
    " " +
    date.toLocaleTimeString("en-US", { hour: "numeric", minute: "2-digit" })
  ).slice(4);
  return (
    <>
      <Box px={4} py={2}>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={2}>
            <Logo />
          </Grid>
          <Grid item xs={8}>
            <Typography align="center" variant="h3">
              SMART CHIP PLAY
            </Typography>
            <Typography align="center" variant="h3" sx={{ color: "#58697B" }}>
              {dateString}
            </Typography>
          </Grid>
          <Grid item xs={2}></Grid>
        </Grid>
      </Box>
      <Box
        px={4}
        pt={10}
        pb={10}
        sx={{
          backgroundColor: "#01663C",
        }}
      >
        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12}>
            <Typography variant="h1" align="center" color="secondary">
              Retailer Support Requests
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Header;
