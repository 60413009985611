import { createTheme as createMuiTheme } from "@mui/material/styles";

const customBlue = {
  50: "#e9f0fb",
  100: "#c8daf4",
  200: "#a3c1ed",
  300: "#7ea8e5",
  400: "#6395e0",
  500: "#6ab7ff",
  600: "#407ad6",
  700: "#1E88E5",
  800: "#2f65cb",
  900: "#005cb2 ",
};

const theme = createMuiTheme({
  spacing: 4,
  palette: {
    mode: "light",
    primary: {
      main: "#01663C",
      contrastText: "#FFF",
    },
    secondary: {
      main: "#FFFFFF",
      contrastText: "#333333",
    },
    background: {
      body: "#F9F9FB",
      default: "#FFFFFF",
      paper: "#FFFFFF",
    },
    ink: {
      ink100: "#000000",
      ink90: "#0f1114",
      ink80: "#20242a",
      ink70: "#41454a",
      ink60: "#626569",
      ink50: "#85878a",
      ink40: "#a6a7aa",
      ink30: "#bcbdbf",
      ink20: "#d2d3d4",
      ink10: "#e8e9e9",
      ink5: "#f7f7f7",
      ink0: "#ffffff",
    },
    red: {
      red90: "#660a00",
      red80: "#8d180b",
      red70: "#cb3525",
      red60: "#ec5341",
      red50: "#ff6a59",
      red40: "#ff7f71",
      red30: "#ff9589",
      red20: "#ffa89e",
      red10: "#ffc5bf",
      red5: "#ffe2df",
    },
    yellow: {
      yellow90: "#4d3900",
      yellow80: "#8c6900",
      yellow70: "#cc9900",
      yellow60: "#f2c744",
      yellow50: "#ffcc33",
      yellow40: "#ffd24d",
      yellow30: "#ffe189",
      yellow20: "#ffedb8",
      yellow10: "#fff5d9",
      yellow5: "#fffbf0",
    },
    green: {
      green90: "#2e4d00",
      green80: "#548c00",
      green70: "#7acc00",
      green60: "#9eeb2a",
      green50: "#abe556",
      green40: "#b8ff4d",
      green30: "#d6ff99",
      green20: "#e6ffbf",
      green10: "#f0ffd9",
      green5: "#f9fff0",
    },
  },
  typography: {
    fontFamily: [
      "Inter",
      "Nunito",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontSize: 36,
      fontWeight: 700,
      lineHeight: 1.25,
    },
    h2: {
      fontSize: 18,
      fontWeight: 500,
      lineHeight: 1.5,
    },
    h3: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: 1.4,
    },
    // h4: {
    //   fontSize: "1.125rem",
    //   fontWeight: 500,
    //   lineHeight: 1.25,
    // },
    // h5: {
    //   fontSize: "1.0625rem",
    //   fontWeight: 500,
    //   lineHeight: 1.25,
    // },
    // h6: {
    //   fontSize: "1rem",
    //   fontWeight: 500,
    //   lineHeight: 1.25,
    // },
    body1: {
      fontSize: 14,
      lineHeight: 1.5,
    },
    button: {
      textTransform: "none",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        endIcon: {
          marginLeft: 0,
          marginRight: 0,
          paddingLeft: 4,
        },
      },
      variants: [
        {
          props: { variant: "contained" },
          style: {
            fontSize: 12,
            lineHeight: 1.5,
            fontWeight: 600,
            padding: "8px 12px",
          },
        },
        {
          props: { variant: "text" },
          style: {
            fontSize: 12,
            lineHeight: 1.5,
            fontWeight: 600,
            padding: "8px 0",
          },
        },
      ],
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          height: 4,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: 14,
          lineHeight: 1.4,
          fontWeight: 700,
        },
      },
    },
  },
});

export default theme;
