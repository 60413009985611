import { GlobalStyles, ThemeProvider } from "@mui/material";
import Header from "./components/Header";
import Content from "./components/Content";
import Footer from "./components/Footer";
import theme from "./utils/theme";
import "./App.css";

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles styles={{ body: { backgroundColor: "#F9F9FB" } }} />
      <Header />
      <Content />
      <Footer />
    </ThemeProvider>
  );
};

export default App;
