import { Paper, Typography } from "@mui/material";

const Footer = () => {
  return (
    <Paper
      elevation={0}
      sx={{
        padding: "24px 0",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography align="center" sx={{ color: "#58697B" }}>
        © {new Date().getFullYear()} PA Lottery
      </Typography>
    </Paper>
  );
};

export default Footer;
