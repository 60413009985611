import React from "react";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
  TextField,
  Skeleton,
  Checkbox,
} from "@mui/material";
import { Field } from "formik";
import PhoneTextFieldComponent from "../components/PhoneTextField";

export const SingleTextField = ({
  field: { name, value, onChange, onBlur },
  form: { touched, errors },
  label,
  disabled,
}) => (
  <TextField
    name={name}
    label={label}
    value={value}
    error={Boolean(touched[name] && errors[name])}
    fullWidth
    helperText={touched[name] && errors[name]}
    onBlur={onBlur}
    onChange={onChange}
    disabled={disabled}
  />
);

export const PhoneTextField = ({
  field: { name, value, onChange, onBlur },
  form: { touched, errors },
  label,
  disabled,
}) => (
  <TextField
    name={name}
    label={label}
    value={value}
    error={Boolean(touched[name] && errors[name])}
    fullWidth
    helperText={touched[name] && errors[name]}
    onBlur={onBlur}
    onChange={onChange}
    disabled={disabled}
    InputProps={{
      inputComponent: PhoneTextFieldComponent,
    }}
  />
);

const RadioButton = ({ label, field: { name, value, checked, onChange } }) => (
  <FormControlLabel
    name={name}
    value={value}
    checked={checked}
    onChange={onChange}
    control={<Radio />}
    label={label}
  />
);

export const RadioGroupField = ({ name, label, options }) => (
  <FormControl>
    <FormLabel id={name}>{label}</FormLabel>
    <RadioGroup aria-labelledby={name} name={name}>
      {options.map(({ value, label }) => (
        <Field
          type="radio"
          name={name}
          label={label}
          value={value}
          component={RadioButton}
          key={value}
        />
      ))}
    </RadioGroup>
  </FormControl>
);

export const CheckboxGroupField = ({ field: { name }, label, options }) => (
  <FormControl>
    <FormLabel id={name}>{label}</FormLabel>
    {options.map(({ value, label }) => (
      <Field
        type="checkbox"
        name={name}
        value={value}
        as={FormControlLabel}
        control={<Checkbox />}
        label={label}
        key={value}
      />
    ))}
  </FormControl>
);

export const SingleSelect = ({
  loading,
  form,
  field,
  label,
  options,
  onChange = () => {},
  sx,
}) => {
  const { name, value } = field;
  const { setFieldValue } = form;

  if (loading) {
    return <Skeleton variant="rectangular" width={280} height={40} />;
  }

  return (
    <FormControl
      fullWidth
      error={Boolean(form.touched[name] && form.errors[name])}
      sx={sx}
    >
      <InputLabel id={`${name}-label`}>{label}</InputLabel>
      <Select
        label={label}
        labelId={`${name}-label`}
        id={name}
        name={name}
        value={value}
        onChange={(event) => {
          setFieldValue(name, event.target.value);
          onChange(event, setFieldValue);
        }}
      >
        {options.map(({ value, display_name }) => (
          <MenuItem value={value} key={value}>
            {display_name}
          </MenuItem>
        ))}
      </Select>
      {form.touched[name] && form.errors[name] && (
        <FormHelperText>{form.errors[name]}</FormHelperText>
      )}
    </FormControl>
  );
};

export const NumberField = ({ form, field, label }) => {
  const { name, value, onBlur, onChange } = field;

  return (
    <TextField
      name={name}
      label={label}
      value={value}
      type="number"
      inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
      error={Boolean(form.touched[name] && form.errors[name])}
      fullWidth
      helperText={form.touched[name] && form.errors[name]}
      onBlur={onBlur}
      onChange={onChange}
    />
  );
};

export const MultilineTextField = ({
  loading,
  form,
  field,
  label,
  disabled,
  sx,
}) => {
  const { name, value, onBlur, onChange } = field;

  if (loading) {
    return <Skeleton variant="rectangular" width={280} height={120} />;
  }

  return (
    <TextField
      name={name}
      label={label}
      value={value}
      error={Boolean(form.touched[name] && form.errors[name])}
      fullWidth
      helperText={form.touched[name] && form.errors[name]}
      onBlur={onBlur}
      onChange={onChange}
      multiline
      maxRows={4}
      minRows={4}
      disabled={disabled}
      sx={sx}
    />
  );
};

export const SingleSelectForFormik = ({
  children,
  form,
  field,
  label,
  labelId,
  id,
  onChange = () => {},
}) => {
  const { name, value } = field;
  const { setFieldValue } = form;

  return (
    <Select
      labelId={labelId}
      id={id}
      label={label}
      name={name}
      value={value}
      onChange={(event) => {
        setFieldValue(name, event.target.value);
        onChange(event);
      }}
    >
      {children}
    </Select>
  );
};

export const MultipleSelectForFormik = ({
  children,
  form,
  field,
  label,
  labelId,
  id,
}) => {
  const { name, value } = field;
  const { setFieldValue } = form;

  return (
    <Select
      labelId={labelId}
      id={id}
      label={label}
      name={name}
      value={value}
      onChange={(e) => {
        setFieldValue(name, e.target.value);
      }}
      multiple
    >
      {children}
    </Select>
  );
};
