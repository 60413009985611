import React, { useCallback, useState } from "react";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  Snackbar,
  Typography,
} from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";
import {
  CheckboxGroupField,
  MultilineTextField,
  PhoneTextField,
  SingleTextField,
} from "../../utils/form";

const FieldInitialValues = {
  Phone: "",
  Message: "",
  Email: "",
  Supplies: [],
};

const FieldNormalize = {
  Supplies: (values) => values.join(", "),
};

const FieldComponents = {
  Phone: ({ loading }) => (
    <Field
      name="Phone"
      label="Phone"
      component={PhoneTextField}
      disabled={loading}
    />
  ),
  Message: ({ loading }) => (
    <Field
      name="Message"
      label="Additional Message"
      component={MultilineTextField}
      disabled={loading}
    />
  ),
  Email: ({ loading }) => (
    <Field
      name="Email"
      label="Email"
      component={SingleTextField}
      disabled={loading}
    />
  ),
  Supplies: ({ loading }) => (
    <Field
      name="Supplies"
      label="Select Required Supplies"
      options={[
        {
          value: "Printer Paper",
          label: "Printer Paper",
        },
      ]}
      component={CheckboxGroupField}
      disabled={loading}
    />
  ),
  "Other Supplies": ({ loading }) => (
    <Field
      name="Other Supplies"
      label="Other Supplies"
      component={MultilineTextField}
      disabled={loading}
    />
  ),
};

const validationFields = {
  Email: Yup.string().email("Invalid email").required("Required"),
  Phone: Yup.string()
    .matches(
      /^(1\s?)?(\d{3}|\(\d{3}\))[\s\-]?\d{3}[\s\-]?\d{4}$/gm,
      "Phone number is incorrect"
    )
    .required("Required"),
  Message: Yup.string(),
  Location: Yup.string(),
  Supplies: Yup.array(),
  "Other Supplies": Yup.string(),
};

const ProductionURL =
  "https://script.google.com/macros/s/AKfycbywPVAY2NcwbZg36SI5F0D1U__IfOAioRjdwoiQ4LkWXNDK4pECWwCCY9hNacKrXzrq/exec";

const DevelopmentURL =
  "https://script.google.com/macros/s/AKfycbzxhn1in5bxaE1JEMa2XOZbAVniTYdOWExtZdfMZEdkahBZZB8xNa01wKe5qHjukI7Zgw/exec";

const URL =
  process.env.REACT_APP_ENVIRONMENT === "dev" ? DevelopmentURL : ProductionURL;

const RequestForm = ({ title, fields, sheetName, open, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const handleSubmit = useCallback(
    async (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
      setLoading(true);
      try {
        const response = await axios.post(
          URL,
          Object.entries(values).reduce(
            (normalizedValues, [key, value]) => ({
              ...normalizedValues,
              [key]: FieldNormalize[key] ? FieldNormalize[key](value) : value,
            }),
            {}
          ),
          {
            headers: {
              "Content-Type": "text/plain",
            },
          }
        );

        console.log(response);

        resetForm();

        setSuccess(true);

        onClose();
      } catch (error) {
        setError(true);
        console.log(error);
      } finally {
        setLoading(false);
      }
    },
    []
  );

  const initialValues = {
    ...fields.reduce(
      (accumulatedFields, field) => ({
        ...accumulatedFields,
        [field]: FieldInitialValues[field],
      }),
      {}
    ),
    Location: new URLSearchParams(window.location.search).get("loc"),
    sheetName,
  };

  const validationSchema = Yup.object().shape({
    ...fields.reduce(
      (accumulatedFields, field) => ({
        ...accumulatedFields,
        [field]: validationFields[field],
      }),
      {}
    ),
    Location: Yup.string(),
  });

  return (
    <>
      <Dialog open={open} onClose={loading ? () => {} : onClose}>
        <DialogContent>
          <Grid container spacing={8} justifyContent="center">
            <Grid item xs={12}>
              <Typography variant="h2">{title}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Box position="relative">
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({ errors, touched }) => (
                    <Form>
                      <Grid container spacing={8}>
                        <Grid item xs={12}>
                          <Grid container spacing={4}>
                            {fields.map((field) => (
                              <Grid item xs={12} key={field}>
                                {React.createElement(FieldComponents[field], {
                                  loading,
                                })}
                              </Grid>
                            ))}
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container spacing={2} alignItems="center">
                            <Grid item>
                              <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                disabled={loading}
                              >
                                Submit
                              </Button>
                            </Grid>
                            <Grid item>
                              <Button
                                color="primary"
                                onClick={onClose}
                                disabled={loading}
                              >
                                Cancel
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Form>
                  )}
                </Formik>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        {loading && (
          <Box
            position="absolute"
            top={0}
            left={0}
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
            height="100%"
          >
            <CircularProgress />
          </Box>
        )}
      </Dialog>
      <Snackbar
        open={success}
        autoHideDuration={5000}
        onClose={() => setSuccess(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          variant="filled"
          onClose={() => setSuccess(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          Your request was successfully sent
        </Alert>
      </Snackbar>
      <Snackbar
        open={error}
        autoHideDuration={5000}
        onClose={() => setError(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          variant="filled"
          onClose={() => setError(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          An error occurred while trying to send your request. Please try again
          later.
        </Alert>
      </Snackbar>
    </>
  );
};

export default RequestForm;
